.form-add-record__title {
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin: 0;
    padding: 30px 0 30px;
}

@media screen and (max-width: 790px) {
    .form-add-record__title {
        font-size: 17px;
        padding: 30px 0 30px;
    }
}