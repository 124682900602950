.search-form__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F9F9F9;
    /* height: 72px; */
    border-radius: 0 9px 9px 0;
    padding: 0 30px 0 29px;
}

@media screen and (max-width: 580px) {
    .search-form__checkbox {
        padding: 0 20px 0 19px;
        margin: 46px 0 0;
        height: max-content;
        background-color: white;
    }
}