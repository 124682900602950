.button-with-background-color {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 110px;
    max-width: 300px;
    padding: 14px 40px;
    background-color: black;
    border-radius: 10px;
    transition: background-color 0.7s, opacity 0.7s;
}

.button-with-background-color:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 0, 0.9);
}

@media screen and (max-width: 790px) {
    .button-with-background-color {
        padding: 10px 20px;
    }
}