.navigator-button {
    display: flex;
    width: 100%;
    height: 259px;
    background-color: #D9D9D9;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    justify-items: stretch;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    transition: background-color 0.7s, box-shadow 0.7s;
    margin: 0 auto;
}

.navigator-button:hover {
    cursor: pointer;
    background-color: yellow;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3)
}

@media screen and (max-width: 972px) {
    .navigator-button {
        min-height: 200px;
    }
}

@media screen and (max-width: 920px) {
    .navigator-button {
        min-height: 200px;
    }
}

@media screen and (max-width: 972px) {
    .navigator-button {
        min-height: 200px;
        min-width: 300px;
    }
}