.search-form__input {
    background-color: #F9F9F9;
    border: none;
    color: #A0A0A0;
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 15px;
}

@media screen and (max-width: 582px) {
    .search-form__input {
        border-radius: 9px 0 0 9px;
        padding-left: 19px;
    }
}