.item {
    width: 100%;
    background-color: rgb(218, 218, 218);
    border-radius: 20px;
    padding: 20px;
    margin: 0 0 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    transition: background-color 0.7s, box-shadow 0.7s;
}

.item:hover {
    cursor: pointer;
    background-color: yellow;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}