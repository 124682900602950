.main-page {
    max-width: 900px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 300px));
    gap: 30px;
    justify-content: center;
}

@media screen and (max-width: 972px) {
    .main-page {
        width: 90%;
        gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 300px));
    }
}