.header-title {
    font-family: 'Inter', 'Helvetica Neue', Arial;
    font-weight: 500;
    font-size: 22px;
}

@media screen and (max-width: 790px) {
    .header-title {
        font-size: 17px;
    }
  }