.input__label {
    margin: 0 !important;
    padding: 0 5px 0 0 !important;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #706F6F;
}

@media screen and (max-width: 790px) {
    .input__label {
        font-size: 14px;
        padding: 0 5px 0 0 !important;
    }
}